import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Page } from "./types";
import Footer from "components/Footer/Footer";
import PageHome from "app/(home)/page";
import PageArchive from "app/(archives)/archive/page";
import PageArchiveVideo from "app/(archives)/archive-3/page";
import PageArchiveAudio from "app/(archives)/archive-2/page";
import PageAuthor from "app/author/page";
import PageSingle from "app/(singles)/(default)/single/page";
import PageSingleTemplate2 from "app/(singles)/(default)/single-2/page";
import PageSingleTemplate3 from "app/(singles)/(has-sidebar)/single-3/page";
import PageSingleGallery from "app/(singles)/(default)/single-gallery/page";
import PageSingleAudio from "app/(singles)/(default)/single-audio/page";
import PageSingleVideo from "app/(singles)/(default)/single-video/page";
import PageSearch from "app/(search)/search/page";
import PageAbout from "app/about/page";
import PageContact from "app/(others)/contact/page";
import Page404 from "app/not-found";
import PageLogin from "app/(others)/login/page";
import PageSignUp from "app/(others)/signup/page";
import PageForgotPass from "app/(others)/forgot-pass/page";
import PageSubcription from "app/(others)/subscription/page";
import PageHomeDemo6 from "app/(home)/home-6/page";
import SiteHeader from "app/SiteHeader";
import PageSingleTemplate4 from "app/(singles)/(has-sidebar)/single-4/page";
import DashboardSubmitPost from "app/(others)/dashboard/submit-post/page";
import DashboardPosts from "app/(others)/dashboard/posts/page";
import DashboardEditProfile from "app/(others)/dashboard/edit-profile/page";
import DashboardSubcription from "app/(others)/dashboard/subscription/page";
import DashboardBilingAddress from "app/(others)/dashboard/billing-address/page";

export const pages: Page[] = [
  { path: "/", component: PageHome },
  { path: "/", component: PageHome },
  { path: "/home-6", component: PageHomeDemo6 },

  // archives page -------------------------------------------------------
  { path: "/archive/*", component: PageArchive },
  { path: "/archive-2/*", component: PageArchiveAudio },
  { path: "/archive-3/*", component: PageArchiveVideo },
  { path: "/author/*", component: PageAuthor },

  // authors
  { path: "/author/anastasios", component: PageAuthor },
  { path: "/author/maroula", component: PageAuthor },
  { path: "/author/angelos", component: PageAuthor },
  { path: "/author/victor", component: PageAuthor },

  // city blogs
  { path: "/city/san-fransisco", component: PageSingle },
  { path: "/city/2022/los-angeles", component: PageSingle },
  { path: "/city/2021/new-work", component: PageSingle },
  { path: "/city/2010/vienna", component: PageSingle },
  { path: "/city/2009/paris", component: PageSingle },
  { path: "/city/2009/milan", component: PageSingle },
  
  // all inclusive blogs
  { path: "/all-inclusive/mallorca", component: PageSingle },
  { path: "/all-inclusive/2023/dominican-republic", component: PageSingle },
  { path: "/all-inclusive/2024/mexico", component: PageSingle },

  // beach blogs
  { path: "/beach/2010/hawaii", component: PageSingle },
  { path: "/beach/2009/dubai", component: PageSingle },
  { path: "/beach/2024/hawaii", component: PageSingle },

  // long weekend blogs
  { path: "/long-weekend/2017/arundel", component: PageSingle },
  { path: "/long-weekend/2024/anaheim", component: PageSingle },

  // roadtrip blogs
  { path: "/roadtrip/2010/la-lasvegas", component: PageSingle },

  // family blogs
  { path: "/family/2017/paris-disneyland", component: PageSingle },
  { path: "/family/2024/disneyland-resort", component: PageSingle },

  // summer blogs
  { path: "/summer/2023/thassos", component: PageSingle },
  { path: "/summer/2024/miami", component: PageSingle },

  // winter blogs
  { path: "/winter/2018/avienmore", component: PageSingle },

  // hotels - airport
  { path: "/hotels/airport/2024/hilton-gatwick", component: PageSingleVideo },
  { path: "/hotels/airport/2024/hilton-gardeninn-heathrow", component: PageSingleVideo },
  { path: "/hotels/airport/2024/hilton-lax", component: PageSingleVideo },
  { path: "/hotels/airport/2024/doubletree-manchester", component: PageSingleVideo },
  { path: "/hotels/airport/2024/hilton-heathrow-terminal-5", component: PageSingleVideo },

  // hotels - city
  { path: "/hotels/city/2024/hilton-wembley", component: PageSingleVideo },
  { path: "/hotels/city/2024/hilton-gardeninn-manchester", component: PageSingleVideo },
  { path: "/hotels/city/2024/hilton-gardeninn-anaheim", component: PageSingleVideo },
  { path: "/hotels/city/2024/hilton-london-olympia", component: PageSingleVideo },
  { path: "/hotels/city/2024/doubletree-london-elstree", component: PageSingleVideo },
  { path: "/hotels/city/2024/homewood-anaheim-resort", component: PageSingleVideo },

  // hotels - all inclusive
  { path: "/hotels/all-inclusive/2024/hardrock-riveramaya", component: PageSingle },

  // hotels - beach
  { path: "/hotels/beach/2024/hilton-hawaiian-village", component: PageSingleVideo },
  { path: "/hotels/beach/2024/doubletree-gates-miami", component: PageSingleVideo },

  // single page -------------------------------------------------------
  { path: "/single/*", component: PageSingle },
  { path: "/single-2/*", component: PageSingleTemplate2 },
  { path: "/single-audio/*", component: PageSingleAudio },
  { path: "/single-video/*", component: PageSingleVideo },
  { path: "/single-gallery/*", component: PageSingleGallery },
  { path: "/single-3/*", component: PageSingleTemplate3 },
  { path: "/single-4/*", component: PageSingleTemplate4 },
  { path: "/single-5/*", component: PageSingleTemplate2 },

  // search -------------------------------------------------------
  { path: "/search", component: PageSearch },

  // other pages -------------------------------------------------------
  { path: "/about", component: PageAbout },
  { path: "/contact", component: PageContact },
  { path: "/page404", component: Page404 },
  { path: "/login", component: PageLogin },
  { path: "/signup", component: PageSignUp },
  { path: "/forgot-pass", component: PageForgotPass },
  { path: "/subscription", component: PageSubcription },
  { path: "/dashboard", component: DashboardSubmitPost },
  { path: "/dashboard/posts", component: DashboardPosts },
  { path: "/dashboard/edit-profile", component: DashboardEditProfile },
  { path: "/dashboard/subscription", component: DashboardSubcription },
  { path: "/dashboard/billing-address", component: DashboardBilingAddress },
  { path: "/dashboard/submit-post", component: DashboardSubmitPost },
];

const MyRoutes = () => {
  return (
    <BrowserRouter>
      <SiteHeader />

      <Routes>
        {pages.map(({ component: Component, path }, index) => {
          return <Route key={index} element={<Component />} id={index.toString()} path={path} />;
        })}
        <Route element={<Page404 />} />
      </Routes>

      <Footer />
    </BrowserRouter>
  );
};

export default MyRoutes;
